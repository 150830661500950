import { defineStore } from 'pinia'
import { ref } from 'vue'
import {
  MARKET_PRICE_DASHBOARD,
  MARKET_ANODE,
  MARKET_CATHODE,
  MARKET_COBALT,
  MARKET_LITHIUM,
  MARKET_LITHIUM_ION_BATTERIES,
  MARKET_NATURAL_GRAPHITE,
  MARKET_NICKEL,
  MARKET_SYNTHETIC_GRAPHITE,
  MARKET_RARE_EARTHS,
  MARKET_MANGANESE,
  MARKET_PHOSPHATE,
  MARKET_FLUORSPAR,
  MARKET_SILICON_ANODE,
  MARKET_SODIUM_ION_BATTERIES,
  MARKET_SOLID_STATE,
  MARKET_RECYCLING,
  MARKET_COPPER,
} from '~/constants/services/market'
import { MARKET } from '~/constants/services/constants'

interface SidebarItem {
  name: string
  open: boolean
}

interface SidebarState {
  [key: string]: SidebarItem[]
}

export const useSidebarStore = defineStore('sidebar', () => {
  const state = ref<SidebarState>({
    [MARKET.slug]: [
      MARKET_PRICE_DASHBOARD.sidebar,
      MARKET_LITHIUM.sidebar,
      MARKET_COPPER.sidebar,
      MARKET_COBALT.sidebar,
      MARKET_NICKEL.sidebar,
      MARKET_NATURAL_GRAPHITE.sidebar,
      MARKET_SYNTHETIC_GRAPHITE.sidebar,
      MARKET_RARE_EARTHS.sidebar,
      MARKET_MANGANESE.sidebar,
      MARKET_PHOSPHATE.sidebar,
      MARKET_FLUORSPAR.sidebar,
      MARKET_ANODE.sidebar,
      MARKET_CATHODE.sidebar,
      MARKET_SILICON_ANODE.sidebar,
      MARKET_LITHIUM_ION_BATTERIES.sidebar,
      MARKET_SODIUM_ION_BATTERIES.sidebar,
      MARKET_SOLID_STATE.sidebar,
      MARKET_RECYCLING.sidebar,
    ],
  })

  const openMenu = (menu: string) => {
    state.value[MARKET.slug] = state.value[MARKET.slug].map((item) => ({
      ...item,
      open: item.name === menu,
    }))
  }

  const toggleMenu = (menu: string) => {
    state.value[MARKET.slug] = state.value[MARKET.slug].map((item) => {
      if (item.name !== menu) return item

      return { ...item, open: !item.open }
    })
  }

  return {
    state,
    openMenu,
    toggleMenu,
  }
})
